<template>
    <b-modal title="Form Piutang Tak Tertagih" id="form-tak-tertagih" no-close-on-backdrop no-close-on-esc>
        <p>Total Pembayaran</p>
        <h2 class="text-center text-danger my-2">
            <strong>Rp {{ formatRupiah(totalDebt) }}</strong>
        </h2>
        <b-form-group label="Pilih Akun" class="mb-2">
            <v-select label="text" :options="akunOptions" v-model="akun"></v-select>
        </b-form-group>
        <b-form-group v-if="isKas" label="Pilih Kas" class="mb-2">
            <v-select label="text" :options="kasOptions" :reduce="option => option.value" v-model="form.id_kas"></v-select>
        </b-form-group>
        <br>
        <template #modal-footer>
            <b-button variant="primary" :disabled="!isValidForm" @click.prevent="submit">
                Simpan
            </b-button>
        </template>
    </b-modal>
</template>
<script>
import {BModal, BFormGroup, BButton} from 'bootstrap-vue'
import vSelect from 'vue-select'
export default {
    components: {
        BModal, BFormGroup, BButton, vSelect
    },
    data: () => ({
        akun: null,
        form: {
            id_akun: null,
            id_kas: null
        },
        jenisSetoran: null
    }),
    computed: {
        isValidForm() {

            if(!this.akun) {
                return false
            }

            if(this.isKas && !this.form.id_kas) {
                return false
            }

            return true
        },
        isKas() {
            if(this.akun && this.akun.kategori && this.akun.kategori.kategori == 'Kas & Bank') {
                return true
            }

            return false
        },
        totalDebt() {
            return this.$store.state.penjualan.totalBadDebt
        },
        kasOptions() {
            return JSON.parse( JSON.stringify(this.$store.state.penjualan.kasOptions) )
        },
        akunOptions() {
            return JSON.parse( JSON.stringify(this.$store.state.penjualan.akunOptions) )
        }
    },
    methods: {
        submit() {
            if(!this.isValidForm) {
                this.displayError({
                    message: 'Harap pastikan form dipilih!'
                })
                return false
            }

            const payload = {
                id: this.$route.params.id
            }
            payload.id_akun = this.akun.value
            payload.id_kas = this.form.id_kas ? this.form.id_kas : null

            this.$emit('submit', payload)
        }
    }
}
</script>