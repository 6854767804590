var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "title": "Form Piutang Tak Tertagih",
      "id": "form-tak-tertagih",
      "no-close-on-backdrop": "",
      "no-close-on-esc": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function () {
        return [_c('b-button', {
          attrs: {
            "variant": "primary",
            "disabled": !_vm.isValidForm
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.submit($event);
            }
          }
        }, [_vm._v(" Simpan ")])];
      },
      proxy: true
    }])
  }, [_c('p', [_vm._v("Total Pembayaran")]), _c('h2', {
    staticClass: "text-center text-danger my-2"
  }, [_c('strong', [_vm._v("Rp " + _vm._s(_vm.formatRupiah(_vm.totalDebt)))])]), _c('b-form-group', {
    staticClass: "mb-2",
    attrs: {
      "label": "Pilih Akun"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "text",
      "options": _vm.akunOptions
    },
    model: {
      value: _vm.akun,
      callback: function ($$v) {
        _vm.akun = $$v;
      },
      expression: "akun"
    }
  })], 1), _vm.isKas ? _c('b-form-group', {
    staticClass: "mb-2",
    attrs: {
      "label": "Pilih Kas"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "text",
      "options": _vm.kasOptions,
      "reduce": function (option) {
        return option.value;
      }
    },
    model: {
      value: _vm.form.id_kas,
      callback: function ($$v) {
        _vm.$set(_vm.form, "id_kas", $$v);
      },
      expression: "form.id_kas"
    }
  })], 1) : _vm._e(), _c('br')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }